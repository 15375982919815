<template>
<div>
  <Spinner />
  <ExportForm />
</div>
</template>

<script>
import ExportForm from '../components/ExportForm.vue';
import Spinner from "../components/Spinner.vue";

export default {
    components: {
        ExportForm,
        Spinner
    },
}
</script>
